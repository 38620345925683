import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { translateText, translatePath } from '../config/translation'

const useStyles = makeStyles(() => ({
  textBlock: {
    textAlign: 'center',
    maxWidth: '640px',
    padding: 0,
  },
}))

export default ({ path, lang }) => {
  const classes = useStyles()

  return (
    <Layout pageName='RSVP' activeRoute={ path }  lang={ lang }>
      <SEO title="RSVP" />
      <Container maxWidth='md'>
        <Box m="48px" />
        <Container className={ classes.textBlock }>
          <p>{ translateText('Invitations were sent by email. Please follow the link in your email to RSVP.', lang) }</p>
          <p></p>
          <Link to={ translatePath("/get-invitation", lang) }>{ translateText("Can't find your invitation?", lang) }</Link>
        </Container>
        {/* <GuestList lang={ lang } /> */}
      </Container>
    </Layout>
  )
}
